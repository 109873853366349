<template>
	<div id="app">
		<v-main>
			<!-- 上布局 -->
			<v-row>
				<v-col>
					<!-- 上布局内容 -->
					<component class="mr-6" is="top" />
				</v-col>
			</v-row>
			<!-- 下布局 -->
			<v-row>
				<!-- 左侧 -->
				<v-col :cols="show_left?show_left_right?2:1:0" style="display: flex;">
					<v-col :cols='show_left_right?3:12' style="max-width: 72px; margin: 0px;padding: 0px;">
						<v-card :height="800" :width="72">
							<v-col align="center" v-for="item in menu_left[currentRouteName]" :key="item.name"
								:style="`${item.component==functionType?'background-color: aliceblue;':''}`"
								@click="menuLeft(item.component)">
								<v-icon color="#1976d2" style="width: 100%;">{{item.icon}}</v-icon>
								<span>{{item.name}}</span>
							</v-col>
						</v-card>
					</v-col>
					<v-col cols='9' style="margin: 0px;padding: 0px;" v-if="show_left_right">
						<v-card :height="800">
							<v-card-title>
								请选择
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text >
								<v-row>
									<v-col  style='padding: 0 12px'>
										<v-select v-model="menuOperationCheckboxId"
											:label="`${functionType==0?'行动':functionType==1?'操作':'从...转换'}`"
											:items="dataSet.Operationlist"></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col  style='padding: 0 12px'>
										<v-select v-model="menuParameterCheckboxId"
											:label="`${functionType==0?'对象':functionType==1?'参数':'转换为...'}`"
											:items="dataSet.Parameterlist"></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col style='padding: 0 12px'>
										<v-select v-model="menuResultsCheckboxId" label="结果类型"
											:items="dataSet.Resultslist"></v-select>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>

					<div class="p-editor-btn">
						<div class="p-editor-btn-bg">
							<img src="@/assets/collapse-btn-light.svg" width="39" height="620"
								style="pointer-events: none;">
						</div>
						<div class="p-editor-btn-icon" @click="show_left_right=!show_left_right">
							<v-icon color="#1976d2" v-if="show_left_right">mdi-chevron-left</v-icon>
							<v-icon color="#1976d2" v-if="!show_left_right">mdi-chevron-right</v-icon>
						</div>
					</div>
				</v-col>
				<!-- 中间 -->
				<v-col :cols="show_left?show_right?show_left_right?8:9:10:show_right?10:12">
					<v-card height="800" style="overflow: auto" v-if="is_choice">
						<v-row v-for="(item,index) in CheckboxArrList" v-if="CheckboxArrList.length>0" :key="item.effectsId" 
							:style="`${index%2==0?'background-color: #ffff7f4a;border-radius: 10px;margin: 0px 5px;':'background-color: #1976d24a;border-radius: 10px;margin: 0px 5px;'}`">
							<v-col cols="2"  >
								<strong>{{index+1}}.{{item.effectsNameTranslate}}</strong>
							</v-col>
							<v-col cols="6" >
								<strong>{{item.effectsDescriptionTranslate}}</strong>
							</v-col>
							<v-col cols="4" >
								<strong>{{item.effectsExampleTranslate}}</strong>
							</v-col>
						</v-row>
						<v-row v-if="CheckboxArrList.length==0">
							<v-col cols="2">
								<strong>暂无数据</strong>
							</v-col>
						</v-row>
					</v-card>
					<v-card height="800" style="overflow: auto" v-if="!is_choice">
						<!-- 中间内容 -->
						<welcomePage class="mr-6" @updatePjo='updatePjo' 
							:router="currentRouteName" />
					</v-card>
				</v-col>
				<!-- 右侧 -->
				<v-col :cols="show_right?2:0">
					<!-- 右侧内容 -->
					<v-card v-show="show_right" height="800" style="overflow: auto">
						<v-card flat class="zdy_bjt" style="">
							<v-row style="height: 90px;">
								<v-col cols="12" elevation="1">
									<img src="../../assets/triz.png" style="width: 100%; margin-left: -22%;" />
									<v-divider class="mx-4 mb-1 " style="margin-top: -70px;"></v-divider>
								</v-col>
							</v-row>
							<v-card height='700' style=" overflow: auto;background: rgba(255, 255, 255, 0.0);" flat>
								<v-col ols="12">
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[0].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[1].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
								</v-col>
							</v-card>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-main>
	</div>
</template>

<script>
	import welcomePage from './component/welcomePage.vue'
	import top from "./component/top.vue"
	import {
		menu_left,
		variants,
	} from '@/store/default.js'
	import {
		getDatabaseCheckboxMenu,
		getCheckboxArrList,
		saveCheckboxArrList
	} from '@/api/effectsDatabase'
	
	export default {
		components: {
			top,
			welcomePage,
		},
		data: () => ({
			functionType: 0,
			menuOperationCheckboxId: null,
			menuParameterCheckboxId: null,
			menuResultsCheckboxId: null,
			CheckboxArrList: [],
			dataSet: {
				EffArrList: [],
				
				Operationlist: [],
				Parameterlist: [],
				Resultslist: [],
			},
			currentRouteName: '',
			menu_left: menu_left,
			variants: variants,
			is_choice: false,
			show_left: true,
			show_right: true,
			show_left_right: true,
			
			
		}),
		computed: {
		},
		created() {
			this.currentRouteName = this.$route.name;
			this.initialize()
		},
		watch: {
			functionType() {
				this.menuOperationCheckboxId=null
				this.menuParameterCheckboxId=null
				this.menuResultsCheckboxId=null
				this.initialize()
			},
			menuOperationCheckboxId(val) {
				if (this.menuOperationCheckboxId != null && this.menuParameterCheckboxId != null && this.menuResultsCheckboxId != null)
					this.getCheckboxArrList()
			},
			menuParameterCheckboxId(val) {
				if (this.menuOperationCheckboxId != null && this.menuParameterCheckboxId != null && this.menuResultsCheckboxId != null)
					this.getCheckboxArrList()
			},
			menuResultsCheckboxId(val) {
				if (this.menuOperationCheckboxId != null && this.menuParameterCheckboxId != null && this.menuResultsCheckboxId != null)
					this.getCheckboxArrList()
			},
		},
		methods: {
			// 左侧菜单点击事件
			menuLeft(con) {
				this.functionType = con
				this.show_left_right = true
			},
			// 子页面点击
			updatePjo(item) {
				this.is_choice = true
				this.$store.commit('updateTriz', item.jsonContent)
				this.openToolsSin = item
				this.triz_toole = item.jsonContent
			},
			initialize() {
				let that=this
				that.is_choice=false
				getDatabaseCheckboxMenu({
						functionType: that.functionType,
						menuOperationCheckboxId: that.menuOperationCheckboxId,
						menuParameterCheckboxId: that.menuParameterCheckboxId,
						menuResultsCheckboxId: that.menuResultsCheckboxId,
					})
					.then((res) => {
						console.log('initialize',res.dataSet)
						that.dataSet = res.data
						that.dataSet.Operationlist=res.data.Operationlist
						// that.dataSet.CheckboxArrList=res.data.CheckboxArrList
						that.dataSet.Resultslist=res.data.Resultslist
						that.dataSet.EffArrList=res.data.EffArrList
						that.dataSet.Resultslist.push({"text":"全部","value": ""})
						console.log('initialize',that.dataSet)
					})
					.catch(() => {
			
					})
			},
			async getCheckboxArrList() {
				let that=this
				// that.dataSet.CheckboxArrList=[]
				getCheckboxArrList({
						functionType: this.functionType,
						menuOperationCheckboxId: this.menuOperationCheckboxId,
						menuParameterCheckboxId: this.menuParameterCheckboxId,
						menuResultsCheckboxId: this.menuResultsCheckboxId,
					})
					.then((res) => {
						that.is_choice=true
						that.CheckboxArrList=res.data.CheckboxArrList
					})
					.catch(() => {
			
					})
			},
		},
	}
</script>

<style lang="scss">
	@import "../../styles/one_triz/index.scss";
	@import "../../styles/new_triz/index.scss";
</style>