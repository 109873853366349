import request from '@/utils/request'
let that = this

//列表
export function geteffectsDatabase(data) {
  return request({
    url: 'effects/admin/query/geteffectsDatabase?page='+data.pageNumber+'&size='+data.pageSize+'&search='+data.search,
    method: 'get'
  })
}
//添加
export function createeffectsDatabase(data) {
  return request({
    url: 'effects/admin/createeffectsDatabase',
    method: 'post',
    data: data
  })
}
//列表
export function geteffectsCheckbox(data) {
  return request({
    url: 'effects/admin/query/geteffectsCheckbox?page='+data.pageNumber+'&size='+data.pageSize+'&search='+data.search+'&functionType='+data.functionType+'&checkboxType='+data.checkboxType,
    method: 'get'
  })
}
//添加
export function createeffectsCheckbox(data) {
  return request({
    url: 'effects/admin/createeffectsCheckbox',
    method: 'post',
    data: data
  })
}
export function editEffectsCheckbox(data) {
  return request({
    url: 'effects/admin/editEffectsCheckbox',
    method: 'post',
    data: data
  })
}
export function editEffectsDatabase(data) {
  return request({
    url: 'effects/admin/editEffectsDatabase',
    method: 'post',
    data: data
  })
}
export function deleteEffectsDatabaseByid(iid) {
  return request({
    url: 'effects/admin/deleteEffectsDatabaseByid/'+iid,
    method: 'delete'
  })
}
export function deleteEffectsCheckboxByid(iid) {
  return request({
    url: 'effects/admin/deleteEffectsCheckboxByid/'+iid,
    method: 'delete'
  })
}
//列表
export function getDatabaseCheckboxMenu(data) {
  return request({
    url: 'effects/query/getDatabaseCheckboxMenu',
    method: 'post',
	data: data
  })
}

export function getCheckboxArrList(data) {
  return request({
    url: 'effects/query/getCheckboxArrList',
    method: 'post',
	data: data
  })
}

export function saveCheckboxArrList(data) {
  return request({
    url: 'effects/query/saveCheckboxArrList',
    method: 'post',
	data: data
  })
}